import React, { createContext, useContext, useEffect, useState } from "react";

const AuthenticationContext = createContext({});

export default function AuthenticationContextProvider({ children }) {
  const [user, setUser] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function loadSession() {
      const selector = document.querySelector("#root-initial-state-data");
      const sessionObject = JSON.parse(selector?.innerHTML || "{}");
      if (!!sessionObject.currentUser) {
        setUser(sessionObject.currentUser);
        setIsLoggedIn(true);
      }
      setIsLoading(false);
    }
    loadSession();
  }, []);

  function getUserAttribute(attr) {
    return (isLoggedIn
      ? {
          ...user.data.attributes,
          id: user.data.id,
        }
      : {})[attr];
  }

  if (isLoading) {
    return null;
  }

  return (
    <AuthenticationContext.Provider
      value={{ user, isLoggedIn, getUserAttribute }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useSession() {
  return useContext(AuthenticationContext);
}
