import React, { useMemo } from "react";
import {
  Inline,
  Avatar,
  AvatarGroup,
  AvatarPlaceholder,
  Stack,
  Frame,
  Text,
  Color,
  Flex,
  TextWeight,
  TextSize,
  FlexDirection,
  FlexDistribution,
  FlexAlignment
} from "@ableco/baseline";
import EmojiConvertor from "emoji-js";
import clsx from "clsx";
import styles from "./Cheers.module.css";

const emojiConvertor = new EmojiConvertor();
emojiConvertor.img_sets.apple.path =
  "https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-apple/6.0.1/img/apple/64/";

const SLACK_CUSTOM_EMOJI_URL = "https://slack.com/api/emoji.list";
let customEmojiMap = {};
function fetchCustomEmojiMap() {
  fetch(`${SLACK_CUSTOM_EMOJI_URL}?token=${process.env.SLACK_API_TOKEN}`)
    .then(response => response.json())
    .then(json => {
      if (json.ok) {
        const { emoji } = json;
        customEmojiMap = Object.assign({}, customEmojiMap, emoji);
      } else {
        console.error("Failed to fetch custom Slack emoji.");
      }
    });
}
fetchCustomEmojiMap();

const customEmojiConverter = text =>
  text.replace(/:([\w/-]+):/gi, match => {
    const lookup = match.replace(/:/g, "");
    let src = customEmojiMap[lookup];
    if (src?.match(/alias/)) {
      src = customEmojiMap[src.replace(/alias:/, "")];
    }
    return `<img width="16px" height="16px" style="display:inline;" src="${src}" />`;
  });

const highlightMentions = text =>
  text.replace(
    /@(\S+)/g,
    match =>
      `<em class="text-primary-base bg-transparent not-italic">${match}</em>`
  );

const truncateString = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, Math.max(0, text.lastIndexOf(" ", maxLength))) + "...";
};

const placeholder = <AvatarPlaceholder size="lg" />;

export default function CheerCard({ cheer }) {
  const content = useMemo(() => {
    const truncatedContent = truncateString(cheer?.content || "", 280);
    const contentWithMentions = highlightMentions(truncatedContent);
    const contentWithEmojis = emojiConvertor.replace_colons(
      contentWithMentions
    );
    const contentWithCustomEmojis = customEmojiConverter(contentWithEmojis);
    return contentWithCustomEmojis;
  }, [cheer]);

  return (
    <Inline className="items-center flex-1" alignment="start">
      <Stack alignment={FlexAlignment.Start}>
        <Inline alignment={FlexAlignment.Stretch}>
          <Stack className="flex-auto">
            <Avatar
              key={cheer?.authorId}
              source={cheer?.authorAvatar}
              name={cheer?.author?.preferredName}
              width={64}
              height={64}
              border="white"
            />
          </Stack>
          <Stack
            alignment={FlexAlignment.Left}
            distribution={FlexDistribution.Center}
            p={[0, 0, 0, 2]}
          >
            <Inline>
              <Stack className="max-w-3xl">
                <Text weight={TextWeight.Bold} className="mb-1">
                  {cheer?.author?.preferredName}{" "}
                  {cheer?.author?.lastName.charAt(0)}.
                  <Text size={TextWeight.Normal}>
                    &nbsp;cheered&nbsp;
                    {(cheer?.mentions || []).map((user, index) => {
                      const name = `${
                        user.preferredName
                      } ${user.lastName.charAt(0)}.`;
                      if (
                        cheer.mentions.length === 1 ||
                        index === cheer.mentions.length - 1
                      ) {
                        return name;
                      } else if (index === cheer.mentions.length - 2) {
                        return `${name} and `;
                      } else {
                        return `${name}, `;
                      }
                    })}
                  </Text>
                </Text>
                <Text
                  as="blockquote"
                  className="leading-tight text-opacity-75"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </Stack>
            </Inline>
          </Stack>
          <Stack p={[0, 0, 0, 5]}>
            <AvatarGroup limit={6} placeholder={placeholder}>
              {(cheer?.mentions || []).map(user => (
                <Avatar
                  key={user.id}
                  source={user.avatarUrl}
                  name={user.preferredName}
                  width={64}
                  height={64}
                  border="white"
                />
              ))}
            </AvatarGroup>
          </Stack>
        </Inline>
      </Stack>
    </Inline>
  );
}
