import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider";
import { useQueryCache } from "react-query";
import { Frame, Stack, Text, TextSize, Color, Avatar } from "@ableco/baseline";
import { usePoll, usePollOptions, usePollAnswers } from "queries/use-polls";
import styles from "./PollPage.module.css";

SwiperCore.use([Autoplay]);

function ResultAnswer({ option, totalAnswers }) {
  const { data: pollAnswers } = usePollAnswers({
    parentId: option.id,
  });
  const containerRef = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(containerRef.current.offsetWidth);
  }, [containerRef]);

  const users = pollAnswers?.included || [];
  const maxItemsInView = Math.ceil(width / (64 + 32));
  const answers = pollAnswers?.meta.recordCount || 0;

  return (
    <Frame className="w-full text-left my-4" innerRef={containerRef}>
      <Text className="ml-16" size={TextSize.XL2} color={Color.White}>
        {option.attributes.title}{" "}
      </Text>
      <Text size={TextSize.LG} color={Color.White}>
        ({`${totalAnswers && ((answers / totalAnswers) * 100).toFixed(2)}%`} •{" "}
        {answers} votes)
      </Text>
      {users.length > 0 && (
        <Swiper
          slidesPerView="auto"
          spaceBetween={32}
          centeredSlides={true}
          speed={3000}
          autoplay={{ delay: 1, disableOnInteraction: false }}
          loop={users.length > maxItemsInView}
          className="mt-4"
        >
          {users.map((user) => {
            return (
              <SwiperSlide key={user.id} style={{ width: "auto" }}>
                <Avatar
                  source={user?.attributes.avatarUrl}
                  name={user?.attributes.name}
                  width={64}
                  height={64}
                  border="white"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Frame>
  );
}

function Results({ id }) {
  const { data: poll, refetch: refetchPoll } = usePoll({ id });
  const { data: pollOptions, refetch: refetchOptions } = usePollOptions({
    parentId: id,
  });
  const queryCache = useQueryCache();

  const handlePollAnswerChange = useCallback(() => {
    refetchPoll();
    refetchOptions();
    queryCache.invalidateQueries("poll-answers");
  }, [refetchOptions, refetchPoll, queryCache]);

  return (
    <Frame className={styles.container} bg={Color.Neutral700}>
      <ActionCableConsumer
        channel={{ channel: "PollChannel", pollId: id }}
        onReceived={handlePollAnswerChange}
      />
      <Stack className="w-full">
        <Text size={TextSize.XL4} color={Color.White} className="mb-16">
          {poll?.data.attributes.question}
        </Text>
        {(pollOptions?.data || []).map((option) => (
          <ResultAnswer
            option={option}
            key={option.id}
            totalAnswers={poll?.data.attributes.answersCount}
          />
        ))}
      </Stack>
    </Frame>
  );
}

export default Results;
