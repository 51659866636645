import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import clsx from "clsx";
import styles from "./PollPage.module.css";
import {
  Frame,
  Text,
  Stack,
  Button,
  Avatar,
  Color,
  TextSize,
} from "@ableco/baseline";
import { useSession } from "contexts/AuthenticationContext";
import { usePoll, usePollOptions } from "queries/use-polls";
import { useVote } from "mutations/use-vote";

function PollAnswers({ poll, readonly = false, handleAnswer = () => null }) {
  const [answer, setAnswer] = useState("");
  const [vote] = useVote(handleAnswer);
  const { data: pollOptions } = usePollOptions({ parentId: poll?.data.id });

  function handleOnClick(answer) {
    if (!readonly) {
      setAnswer(answer);
    }
  }

  useEffect(() => {
    if (poll) {
      setAnswer(poll.data.attributes.votedOption || "");
    }
  }, [poll]);

  useEffect(() => {
    if (!readonly && poll?.data.attributes.votedOption !== answer) {
      vote({ pollOptionId: answer });
    }
  }, [readonly, vote, answer, poll]);

  return (
    <Stack
      className={clsx(styles.answers, "mt-8", { [styles.readonly]: readonly })}
    >
      {(pollOptions?.data || []).map(({ id, attributes }) => (
        <Button
          bg={Color.White}
          state="normal"
          key={id}
          className={clsx("mb-4", "text-left", {
            [styles.activeAnswer]:
              answer.toString() === id.toString() && !readonly,
          })}
          onClick={() => handleOnClick(id)}
        >
          <Text>{attributes.title}</Text>
        </Button>
      ))}
    </Stack>
  );
}

function Poll({
  id,
  duration = 0,
  readonly = false,
  resultsHandler = () => null,
}) {
  const [timeLeft, setTimeLeft] = useState((duration * 60 * 1000) / 2);

  useEffect(() => {
    setTimeLeft((duration * 60 * 1000) / 2);
  }, [id, duration]);

  useEffect(() => {
    if (readonly && duration > 0) {
      const timer = setInterval(() => {
        setTimeLeft((timeLeft) => {
          return Math.max(timeLeft - 1000, 0);
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [id, readonly, duration]);

  const formattedTimeLeft = useMemo(() => {
    return new Date(timeLeft).toISOString().slice(14, 19);
  }, [timeLeft]);

  const { data: poll } = usePoll({ id });
  const pollUrl = useMemo(() => `${process.env.APP_HOST}/p/${poll?.data.id}`, [
    poll,
  ]);

  useEffect(() => {
    if (timeLeft <= 0) {
      resultsHandler(true);
    }
  }, [id, duration, resultsHandler, timeLeft]);

  const session = useSession();
  const avatarUrl = session.getUserAttribute("avatarUrl");
  return (
    <Frame
      className={[styles.container, styles.centerContainer]}
      bg={Color.Neutral700}
    >
      <Stack>
        {!readonly && <Avatar className="mb-3 m-auto" source={avatarUrl} />}
        <Text
          as="h2"
          className="mb-3 mt-3"
          size={TextSize.XL4}
          color={Color.White}
        >
          {poll?.data.attributes.question}
        </Text>
        {readonly && pollUrl && (
          <Text
            as="p"
            className={styles.text}
            color={Color.White}
            className="mb-6"
            size={TextSize.LG}
          >
            Share your opinion: {pollUrl}
          </Text>
        )}
        <PollAnswers poll={poll} readonly={readonly} />
        {readonly && (
          <Stack className="mt-4" space={2}>
            <Text size={TextSize.LG} color={Color.White}>
              Time left to answer
            </Text>
            <Text size={TextSize.LG} color={Color.White}>
              {formattedTimeLeft}
            </Text>
          </Stack>
        )}
      </Stack>
    </Frame>
  );
}

export default Poll;
