import React, { useCallback, useEffect, useState } from "react";
import {
  Text,
  Touchable,
  Flex,
  AvatarGroup,
  Avatar,
  Frame,
  Inline,
  Color,
  TextWeight,
  Inset,
} from "@ableco/baseline";
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider";

export default function MenuItem({
  topic,
  isSelected = false,
  onTopicClicked,
}) {
  const [timeEllapsed, setTimeEllapsed] = useState(0);
  useEffect(() => {
    if (isSelected) {
      const id = setInterval(() => {
        setTimeEllapsed((timeEllapsed) =>
          Math.min(timeEllapsed + 1000, topic.duration * 60 * 1000)
        );
      }, 1000);
      return () => {
        clearInterval(id);
      };
    }
  }, [topic, isSelected]);

  const handleTopicClick = useCallback(
    function handleTopicClick() {
      onTopicClicked(topic);
    },
    [topic, onTopicClicked]
  );

  const bgWidth = (100 * timeEllapsed) / (topic.duration * 60 * 1000);

  const presenterAvatars =
    topic.presenters.length > 0 ? (
      topic.presenters.map((presenter) => (
        <Avatar
          key={presenter.id}
          source={presenter.user?.avatarUrl}
          name={presenter.user?.name}
          width={32}
          height={32}
          border="white"
        />
      ))
    ) : (
      <Frame style={{ width: 32, height: 32 }} />
    );

  return (
    <Touchable
      bg={isSelected ? Color.Neutral100 : undefined}
      onClick={handleTopicClick}
      className="relative"
    >
      <Inset variant="absolute" position="full">
        <Frame
          bg={Color.Neutral200}
          style={{
            width: `${bgWidth}%`,
            height: "100%",
          }}
          className="transition-all duration-75"
        ></Frame>
      </Inset>
      <Flex
        direction="row"
        alignment="center"
        className="p-3 border-b border-neutral-200 relative"
      >
        <Text whitespace="no-wrap" weight={TextWeight.Medium}>
          {topic.title}
        </Text>
        <Inline className="flex-grow justify-end">
          <AvatarGroup p={[1, 2, 1, 4]}>{presenterAvatars}</AvatarGroup>
          <Text
            color={Color.Neutral300}
            className="w-8"
          >{`${topic.duration}m`}</Text>
        </Inline>
      </Flex>
    </Touchable>
  );
}
