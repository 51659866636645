import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Center,
  Color,
  Text,
  TextSize,
  Frame,
  FlexAlignment,
  Stack,
} from "@ableco/baseline";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider";
import JSONAPI from "utils/jsonapi-parse";
import { useTopics } from "queries/use-topics";
import GoogleSlidesEmbed from "components/library/google-slides-embed";
import Layout from "components/library/Layout";
import Agenda from "./Agenda";
import CheersSlider from "./Cheers";
import Poll from "components/pages/PollPage/Poll";
import Results from "components/pages/PollPage/Results";

function SlideSection({ topic }) {
  const [currentSlide, setCurrentSlide] = useState("p");
  const cableController = React.useRef(null);

  const startTimer = useCallback(
    () => cableController.current.send({ timerStart: true }),
    [topic]
  );

  const handleSlideChange = useCallback(
    function handleSlideChange({ slideId }) {
      setCurrentSlide(slideId);
    },
    [setCurrentSlide]
  );

  return (
    <Frame className="w-full h-full">
      <ActionCableConsumer
        channel={{ channel: "TopicChannel", topicId: topic.id }}
        onReceived={handleSlideChange}
      >
        <ActionCableConsumer
          channel={{ channel: "PresentationChannel", id: topic.id }}
          ref={cableController}
          onConnected={startTimer}
        />
        <GoogleSlidesEmbed
          slidesLink={topic.attachmentUrl}
          width="100%"
          height="100%"
          allowFullScreen={false}
          slideId={currentSlide}
        />
      </ActionCableConsumer>
    </Frame>
  );
}

function VideoSection({ topic }) {
  return (
    <ReactPlayer width="100%" height="100%" url={topic.attachmentUrl} playing />
  );
}

function TopicSection({ topic }) {
  const [showPollResults, setShowPollResults] = useState(false);

  useEffect(() => {
    setShowPollResults(false);
  }, [topic]);

  return useMemo(() => {
    if (topic.kind === "slides") {
      return <SlideSection topic={topic} />;
    } else if (topic.kind === "video") {
      return <VideoSection topic={topic} />;
    } else if (topic.kind === "poll") {
      return showPollResults ? (
        <Results id={topic.attachmentUrl} />
      ) : (
        <Poll
          id={topic.attachmentUrl}
          duration={topic.duration}
          resultsHandler={setShowPollResults}
          readonly
        />
      );
    }

    return (
      <Center className="w-full justify-center">
        <Text style={{ fontSize: 45 }} color={Color.White}>
          {topic.title}
        </Text>
      </Center>
    );
  }, [topic, showPollResults]);
}

function MeetingPage() {
  const { meetingId } = useParams();
  const [currentTopic, setcurrentTopic] = useState();
  const { data: meetingTopics } = useTopics({ meetingId });
  const topics = useMemo(() => {
    if (meetingTopics) {
      const { data } = JSONAPI.parse(meetingTopics);
      return data.sort((a, b) => a.sortOrder - b.sortOrder);
    }
    return [];
  }, [meetingTopics]);

  const date = new Date();
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: "long" }).format(date);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  const today = `${month} ${day}, ${year}`;

  return (
    <Layout
      mainContent={
        currentTopic ? (
          <TopicSection topic={currentTopic} />
        ) : (
          <Frame className="w-full">
            <Stack alignment={FlexAlignment.Center}>
              <Text size={TextSize.XL5} color={Color.White} className="mb-5">
                Company All-hands
              </Text>
              <Text size={TextSize.XL2} color={Color.Neutral300}>
                {today}
              </Text>
            </Stack>
          </Frame>
        )
      }
      sidebarContent={
        meetingId && (
          <Agenda
            topics={topics}
            currentTopic={currentTopic}
            onTopicChange={setcurrentTopic}
          />
        )
      }
      bottomContent={<CheersSlider meetingId={meetingId} />}
    />
  );
}

export default MeetingPage;
