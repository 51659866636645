import * as React from "react";

export function ArrowLeft(props) {
  return (
    <svg width={9} height={14} fill="none" fillRule="evenodd" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86383 13.531C7.57093 13.8239 7.09606 13.8239 6.80317 13.531L1.08621 7.81405C1.08608 7.81392 1.08596 7.81379 1.08583 7.81367C0.979105 7.70699 0.894418 7.58034 0.836614 7.44093C0.778744 7.30136 0.748959 7.15176 0.748959 7.00067C0.748959 6.84959 0.778744 6.69998 0.836614 6.56041C0.894418 6.421 0.979105 6.29435 1.08583 6.18768C1.08596 6.18755 1.08608 6.18742 1.08621 6.1873L6.80316 0.470341C7.09606 0.177447 7.57093 0.177447 7.86382 0.470341C8.15672 0.763234 8.15672 1.23811 7.86383 1.531L2.39416 7.00067L7.86383 12.4703C8.15672 12.7632 8.15672 13.2381 7.86383 13.531Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowRight(props) {
  return (
    <svg width={9} height={14} fill="none" fillRule="evenodd" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.13617 0.470341C1.42907 0.177448 1.90394 0.177448 2.19683 0.470341L7.91379 6.1873C7.91392 6.18742 7.91404 6.18755 7.91417 6.18767C8.02089 6.29435 8.10558 6.421 8.16339 6.56041C8.22126 6.69998 8.25104 6.84959 8.25104 7.00067C8.25104 7.15176 8.22126 7.30136 8.16339 7.44093C8.10558 7.58034 8.02089 7.70699 7.91417 7.81367C7.91404 7.81379 7.91392 7.81392 7.91379 7.81405L2.19683 13.531C1.90394 13.8239 1.42907 13.8239 1.13617 13.531C0.843281 13.2381 0.843281 12.7632 1.13617 12.4703L6.60584 7.00067L1.13617 1.531C0.843281 1.23811 0.843281 0.763234 1.13617 0.470341Z"
        fill="currentColor"
      />
    </svg>
  );
}
