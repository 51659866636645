import React from "react";
import { useParams } from "react-router-dom";
import { usePoll } from "queries/use-polls";
import Poll from "./Poll";

function PollPage() {
  const { pollId } = useParams();

  return <Poll id={pollId} />;
}

export default PollPage;
