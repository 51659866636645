import * as React from "react";
import {
  Inline,
  Stack,
  Button,
  Text,
  TextSize,
  Color,
  FlexDistribution,
  Corners,
  TextWeight,
  TextLeading,
  Tooltip,
  Icon,
  Place,
  Shadow,
} from "@ableco/baseline";
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider";
import GoogleSlidesEmbed from "components/library/google-slides-embed";
import { ArrowLeft, ArrowRight } from "./icons";

// Extracts the slide id from the share-able url
function extractSlidesKey(slidesUrl) {
  const regex = new RegExp(
    `(((https|http):\/\/|)docs\.google\.com\/presentation\/d\/)(.+?(?=(\/.+|\/|$)))`
  );

  const match = regex.exec(slidesUrl);

  return match ? match[4] : null;
}

async function getSlides(presentationId, googleAccessToken) {
  const url = `https://slides.googleapis.com/v1/presentations/${presentationId}?fields=slides.objectId`;
  const headers = {
    Authorization: "Bearer " + googleAccessToken,
    "Content-Type": "application/json; charset=utf-8",
  };

  const res = await fetch(url, { headers });
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  if (res.status == 204) {
    return {};
  }
  return await res.json();
}

async function getSpeakerNotes(presentationId, googleAccessToken, slideId) {
  const url = `https://slides.googleapis.com/v1/presentations/${presentationId}/pages/${slideId}`;
  const headers = {
    Authorization: "Bearer " + googleAccessToken,
    "Content-Type": "application/json; charset=utf-8",
  };
  const res = await fetch(url, { headers });
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  if (res.status == 204) {
    return {};
  }
  return await res.json();
}

function SpeakerNotes({ slideObject }) {
  let notes = [];

  const notesObj = slideObject.slideProperties.notesPage?.pageElements;
  notesObj.forEach((note) => {
    const textObj = note.shape.text;
    if (textObj && note.shape.shapeType === "TEXT_BOX") {
      textObj.textElements.forEach((element) => {
        if (element.textRun) {
          notes.push(element.textRun.content);
        }
      });
    }
  });

  return (
    <Stack className="h-full" style={{ width: 500 }} space={4}>
      <Inline>
        <Text
          color={Color.Neutral800}
          size={TextSize.XL}
          weight={TextWeight.SemiBold}
        >
          Speaker Notes
        </Text>
      </Inline>
      <Stack
        space={2}
        corners={Corners.LargeRounded}
        shadow={Shadow.Medium}
        bg={Color.White}
        p={[4, 2]}
      >
        <Stack p={[0, 2, 0, 8]}>
          {notes.length > 0 ? (
            notes.map((note, idx) => (
              <Text
                key={idx}
                size={TextSize.SM}
                weight={TextWeight.Normal}
                color={Color.Neutral700}
                leading={TextLeading.Relaxed}
              >
                {note}
              </Text>
            ))
          ) : (
            <Text size={TextSize.SM} color={Color.Neutral500}>
              There are no notes to show...
            </Text>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

function Timer({ totalTime, currentTime }) {
  const [seconds, setSeconds] = React.useState("0");
  const [minutes, setMinutes] = React.useState("0");
  const [isNearEnd, setIsNearEnd] = React.useState(false);
  const warningThreshold = totalTime * 0.85; // to show a warning color in the timer when there's just 15% of the time left

  React.useEffect(() => {
    const minutes = Math.floor(currentTime / 60);
    const seconds = Math.ceil(currentTime % 60);
    setMinutes(minutes.toString().padStart(2, "0"));
    setSeconds(seconds.toString().padStart(2, "0"));
    if (
      minutes >= Math.floor(warningThreshold) &&
      seconds >= (warningThreshold % 1) * 60
    )
      setIsNearEnd(true);
  }, [currentTime]);

  return (
    <Inline
      space={2}
      corners={Corners.LargeRounded}
      shadow={Shadow.Medium}
      bg={Color.White}
      p={4}
    >
      <Text
        color={isNearEnd ? Color.Warning : Color.Neutral800}
        weight={TextWeight.Bold}
        size={TextSize.XL}
      >
        Time:
      </Text>
      <Text
        color={isNearEnd ? Color.Warning : Color.Neutral800}
        size={TextSize.LG}
      >
        {minutes}:{seconds} / {totalTime} minutes
      </Text>
    </Inline>
  );
}

async function requestGoogleToken() {
  const res = await fetch("/auth/google/token", {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  if (!res.ok) throw new Error(res.statusText);
  if (res.status == 204) {
    return {};
  }
  return await res.json();
}

export default function PresentationControl({ topic }) {
  const [slides, setSlides] = React.useState(null);
  const [slideObject, setSlideObject] = React.useState(null);
  const [currentSlide, setCurrentSlide] = React.useState(null);
  const [timerStarted, setTimerStarted] = React.useState(false);
  const [presentationTimer, setPresentationTimer] = React.useState(0);
  const attachmentUrl = React.useMemo(
    () => topic?.data.attributes.attachmentUrl,
    [topic]
  );

  const cableController = React.useRef(null);
  const slidesKey = extractSlidesKey(attachmentUrl);
  React.useEffect(() => {
    async function fetchData() {
      const accessToken = await requestGoogleToken();
      const result = await getSlides(
        slidesKey,
        accessToken.google_access_token
      );
      const slidesList = result.slides.map((slide) => slide.objectId);
      setCurrentSlide({ index: 0, id: slidesList[0] });
      setSlides(slidesList);
    }
    fetchData();
  }, []);

  const handlePresentationTimer = React.useCallback(
    function handlePresentationTimer({ timerStart }) {
      setTimerStarted(timerStart);
    },
    [setTimerStarted]
  );

  React.useEffect(() => {
    if (timerStarted) {
      const interval = setInterval(() => {
        setPresentationTimer(presentationTimer + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [timerStarted, presentationTimer, setPresentationTimer]);

  React.useEffect(() => {
    if (currentSlide) {
      cableController.current?.send({ slideId: currentSlide.id });
      async function fetchSlideObject() {
        const accessToken = await requestGoogleToken();
        const result = await getSpeakerNotes(
          slidesKey,
          accessToken.google_access_token,
          currentSlide.id
        );
        setSlideObject(result);
      }
      fetchSlideObject();
    }
  }, [currentSlide, slidesKey]);

  const selectPrevSlide = React.useCallback(
    async function selectPrevSlide(ev) {
      if (prevSlideButtonDisabled) return;
      ev.preventDefault();
      if (currentSlide.index === 0) return;
      const newIndex = currentSlide.index - 1;
      setCurrentSlide({ index: newIndex, id: slides[newIndex] });
    },
    [currentSlide, setCurrentSlide, slides, prevSlideButtonDisabled]
  );

  const selectNextSlide = React.useCallback(
    async function selectNextSlide(ev) {
      if (nextSlideButtonDisabled) return;
      ev.preventDefault();
      const newIndex = currentSlide.index + 1;
      if (newIndex === slides.length) return;
      setCurrentSlide({ index: newIndex, id: slides[newIndex] });
    },
    [currentSlide, setCurrentSlide, slides, nextSlideButtonDisabled]
  );

  if (!slides || !topic || !currentSlide || !slideObject) return null;

  const prevSlideButtonDisabled = currentSlide.index === 0;
  const nextSlideButtonDisabled = currentSlide.index === slides.length - 1;

  return (
    <Stack space={2}>
      <ActionCableConsumer
        channel={{ channel: "PresentationChannel", id: topic.data.id }}
        onReceived={handlePresentationTimer}
      />
      <ActionCableConsumer
        channel={{ channel: "TopicChannel", topicId: topic.data.id }}
        ref={cableController}
      />
      <Inline space={2} className="items-stretch">
        <GoogleSlidesEmbed
          key={currentSlide.id}
          slidesLink={attachmentUrl}
          allowFullScreen={false}
          width="100%"
          height={760}
          slideId={currentSlide.id}
          disabled
        />
        <Stack space={4} p={[4, 1]}>
          <Inline
            className="items-stretch"
            space={10}
            distribution={FlexDistribution.Start}
          >
            <Tooltip
              place={Place.Top}
              label={
                <Text color={Color.White} size={TextSize.LG}>
                  Previous Slide
                </Text>
              }
            >
              <Button
                className={[
                  "w-10",
                  {
                    "cursor-not-allowed": prevSlideButtonDisabled,
                  },
                ]}
                variant={prevSlideButtonDisabled ? "solid" : "outline"}
                color={
                  prevSlideButtonDisabled ? Color.Neutral100 : Color.Secondary
                }
                size="xl"
                corners={Corners.LargeRounded}
                onClick={selectPrevSlide}
              >
                <Icon
                  color={
                    prevSlideButtonDisabled ? Color.Neutral300 : Color.Secondary
                  }
                >
                  <ArrowLeft />
                </Icon>
              </Button>
            </Tooltip>
            <Tooltip
              place={Place.Top}
              label={
                <Text color={Color.White} size={TextSize.LG}>
                  Next Slide
                </Text>
              }
            >
              <Button
                className={[
                  "w-10",
                  {
                    "cursor-not-allowed": nextSlideButtonDisabled,
                  },
                ]}
                variant={nextSlideButtonDisabled ? "solid" : "outline"}
                color={
                  nextSlideButtonDisabled ? Color.Neutral100 : Color.Secondary
                }
                size="xl"
                corners={Corners.LargeRounded}
                onClick={selectNextSlide}
              >
                <Icon
                  color={
                    nextSlideButtonDisabled ? Color.Neutral300 : Color.Secondary
                  }
                >
                  <ArrowRight />
                </Icon>
              </Button>
            </Tooltip>
          </Inline>
          <Timer
            totalTime={topic.data.attributes.duration}
            currentTime={presentationTimer}
          />
          <SpeakerNotes slideObject={slideObject} />
        </Stack>
      </Inline>
    </Stack>
  );
}
