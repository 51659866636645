import React, { useState, useEffect } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { ReactQueryDevtools } from "react-query-devtools";
import { ActionCableProvider } from "@thrash-industries/react-actioncable-provider";
import GlobalContextProvider from "contexts/GlobalContext";
import AuthenticationContextProvider from "contexts/AuthenticationContext";
import MainRouter from "routers/MainRouter";

import "swiper/swiper.scss";
import "styles/index.css";

function Startup() {
  const [isReady, setIsReady] = useState(false);

  const loadData = async () => {
    setIsReady(true);
  };

  useEffect(() => {
    loadData();
  }, []);

  return isReady ? <MainRouter /> : null;
}

function App() {
  return (
    <ActionCableProvider url={process.env.WS_URL}>
      <ErrorBoundary>
        <GlobalContextProvider>
          <AuthenticationContextProvider>
            <Startup />
          </AuthenticationContextProvider>
        </GlobalContextProvider>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen />
    </ActionCableProvider>
  );
}
export default App;
