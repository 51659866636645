import React, { Component } from "react";
import ErrorReport from "./Error";

class ErrorBoundary extends Component {
  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { eventId: null, hasError: false };
  }

  render() {
    // TODO: set handler for reporting error
    if (this.state.hasError) {
      return <ErrorReport />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
