// Adapted from https://github.com/mysidewalk/jsonapi-parse

function isObject(value) {
  return Object.prototype.toString.call(value) === "[object Object]";
}

function isUndefined(value) {
  return value === undefined;
}

function each(collection, iterator) {
  let key;
  if (Array.isArray(collection)) {
    for (key = 0; key < collection.length; key += 1) {
      iterator(collection[key], key);
    }
  } else if (isObject(collection)) {
    for (key in collection) {
      if (Object.prototype.hasOwnProperty.call(collection, key)) {
        iterator(collection[key], key);
      }
    }
  }
}

function map(collection, iterator) {
  const transformed = [];
  each(collection, function(value, key) {
    transformed.push(iterator(value, key));
  });
  return transformed;
}

function every(collection) {
  let passes = true;
  each(collection, function(value) {
    if (value !== true) {
      passes = false;
    }
  });
  return passes;
}

function findWhere(collection, matches) {
  let match;
  each(collection, function(value) {
    const where = map(matches, function(shouldMatch, property) {
      return value[property] === shouldMatch;
    });
    if (every(where)) {
      match = value;
    }
  });
  return match;
}

function extend() {
  const combined = Object(null),
    sources = Array.prototype.slice.call(arguments);
  each(sources, function(source) {
    each(source, function(value, key) {
      combined[key] = value;
    });
  });
  return combined;
}

// Parse a HTTP Response using the JSONAPI format: http://jsonapi.org/format/
const JSONAPI = {
  parse: function(json) {
    if (
      isUndefined(json.data) &&
      isUndefined(json.errors) &&
      isUndefined(json.meta)
    ) {
      return json;
    }
    return deserialize(json);
  },
};

// Deserialize the JSONAPI formatted object
function deserialize(json) {
  let data, deserialized;
  const includedMap = {};
  each(json.included, function(value) {
    const key = value.type + "-" + value.id;
    includedMap[key] = value;
  });

  if (Array.isArray(json.data)) {
    data = map(json.data, function(record) {
      populateRelatedFields(record, includedMap);
      return flatten(record);
    });
  } else if (isObject(json.data)) {
    populateRelatedFields(json.data, includedMap);
    data = flatten(json.data);
  }

  deserialized = {
    data: data,
  };

  if (json.meta) {
    deserialized.meta = json.meta;
  }

  if (json.errors) {
    deserialized.errors = json.errors;
  }

  if (json.links) {
    deserialized.links = json.links;
  }

  return deserialized;
}

// Populate relations of the provided record from the included objects
function populateRelatedFields(record, includedMap, parents) {
  // IF: Object has relationships, update so this record is listed as a parent
  if (record.relationships) {
    parents = parents ? parents.concat([record]) : [record];
  }
  if (!record.attributes) {
    record.attributes = {};
  }

  each(record.relationships, function(relationship, property) {
    // IF: relationship describes non-record specific meta;
    // append relationship meta to the record
    if (relationship && isObject(relationship.meta)) {
      record.meta = record.meta || {};
      record.meta[property] = relationship.meta;
    }

    // IF: No relationship data, don't add anything
    if (!relationship.data) {
      return;
    }

    // IF: Relationship has multiple matches, create an array for matched records
    // ELSE: Assign relationship directly to the property
    if (Array.isArray(relationship.data)) {
      record.attributes[property] = map(relationship.data, function(data) {
        return getMatchingRecord(data, includedMap, parents);
      });
    } else {
      record.attributes[property] = getMatchingRecord(
        relationship.data,
        includedMap,
        parents
      );
    }
  });
}

// Retrieves the record from the included objects that matches the provided relationship
function getMatchingRecord(relationship, included, parents) {
  let circular, match;

  circular = findWhere(parents, {
    id: relationship.id,
    type: relationship.type,
  });

  if (circular) {
    return relationship;
  }

  const key = relationship.type + "-" + relationship.id;
  match = included[key];

  // IF: No match or match is the same as parent, return the relationship information
  if (!match) {
    return relationship;
  }

  populateRelatedFields(match, included, parents);

  // IF: relationship defined meta, merge with record provided meta
  const contextSpecificMeta = {};
  if (relationship && isObject(relationship.meta)) {
    contextSpecificMeta = extend({}, match.meta, relationship.meta);
  }

  return flatten(match, contextSpecificMeta);
}

// Flatten the ID of an object with the rest of the attributes on a new object
function flatten(record, extraMeta) {
  const meta = extend({}, record.meta, extraMeta);
  return extend({}, { links: record.links, meta: meta }, record.attributes, {
    id: record.id,
    type: record.type,
  });
}

export default JSONAPI;
