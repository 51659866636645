import { useQuery } from "react-query";
import { read } from "utils/api";

function getTopics({ meetingId }) {
  return meetingId
    ? read(
        `meetings/${meetingId}/topics?include=presenters.user&fields[users]=name,avatarUrl`,
        { rawPath: true }
      )
    : undefined;
}

export function useTopics({ meetingId }) {
  return useQuery("topics", () => getTopics({ meetingId }));
}

function getTopic({ id }) {
  return id
    ? read("topics", {
        resourceId: id,
      })
    : undefined;
}

export function useTopic({ id }) {
  return useQuery(["topics", Number(id)], () => getTopic({ id }));
}

function getTopicsByUser({ userId }) {
  return userId
    ? read(`topics?include=meeting&filter[user]=${userId}`, { rawPath: true })
    : undefined;
}

export function useUserTopics({ userId }) {
  return useQuery(["user-topics", Number(userId)], () =>
    getTopicsByUser({ userId })
  );
}
