import React from "react";
import { Stack } from "@ableco/baseline";
import AgendaTopic from "./AgendaTopic";

export default function Agenda({ topics, currentTopic, onTopicChange }) {
  return (
    <Stack className="w-full">
      {topics.map(topic => {
        return (
          <AgendaTopic
            topic={topic}
            key={topic.id}
            isSelected={topic.id === currentTopic?.id}
            onTopicClicked={onTopicChange}
          />
        );
      })}
    </Stack>
  );
}
