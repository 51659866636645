import React from "react";
import styles from "./HomePage.module.css";
import { Frame, Inline, Text, Shadow } from "@ableco/baseline";
import GoogleButton from "icons/google.svg";

function HomePage() {
  return (
    <Frame className={styles.wrapper}>
      <a href="/auth" className={styles.link}>
        <Frame shadow={Shadow.Large}>
          <Inline className={styles.buttonWrapper}>
            <GoogleButton className={styles.button} />
            <Text className={styles.text}>Sign in using Google</Text>
          </Inline>
        </Frame>
      </a>
    </Frame>
  );
}

export default HomePage;
