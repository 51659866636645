import * as React from "react";
import { Color, Inline, Shadow, Stack, Icon } from "@ableco/baseline";
import AbleLogo from "icons/able.svg";

export default function Layout({ mainContent, sidebarContent, bottomContent }) {
  return (
    <Stack className="box-border w-screen h-screen" p={2}>
      <Inline
        bg={Color.White}
        shadow={Shadow.Base}
        alignment="stretch"
        className="w-full mt-2 flex-grow"
      >
        <Inline className="flex-grow h-full rounded-l w-9/12" bg={Color.Black}>
          {mainContent}
        </Inline>
        <Inline
          alignment="stretch"
          bg={Color.White}
          className="rounded-r overflow-y-auto w-3/12"
        >
          {sidebarContent}
        </Inline>
      </Inline>
      <Inline p={[0, 4]} className="h-32">
        {bottomContent}
        <Icon className="flex-none">
          <AbleLogo />
        </Icon>
      </Inline>
    </Stack>
  );
}
