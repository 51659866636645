import { useQuery } from "react-query";
import { read } from "utils/api";

function getPoll({ id }) {
  return read("polls", {
    resourceId: id,
  });
}

function getPollOptions({ parentId }) {
  return !!parentId
    ? read("poll-options", {
        snakeCasify: false,
        parentId,
        parentName: "polls",
      })
    : null;
}

function getPollAnswers({ parentId }) {
  return !!parentId
    ? read(`poll-options/${parentId}/poll-answers?include=user`, {
        rawPath: true,
        snakeCasify: false,
      })
    : null;
}

export function usePollOptions({ parentId }, options) {
  return useQuery(
    ["poll-options", Number(parentId)],
    () => getPollOptions({ parentId }),
    options
  );
}

export function usePollAnswers({ parentId }, options) {
  return useQuery(
    ["poll-answers", Number(parentId)],
    () => getPollAnswers({ parentId }),
    options
  );
}

export function usePoll({ id }, options) {
  return useQuery(["polls", Number(id)], () => getPoll({ id }), options);
}
