import React from "react";

function ErrorReport() {
  return (
    <div>
      <h1>Oops! Something went wrong</h1>
    </div>
  );
}

export default ErrorReport;
