import React from "react";
import ReactDom from "react-dom";
import App from "components/App";

export default function renderApplication() {
  function renderApp(AppComponent) {
    ReactDom.render(<AppComponent />, document.getElementById("root"));
  }

  if (module.hot) {
    module.hot.accept("components/App/index.js", () => {
      const HotLoadedApp = require("components/App/index.js").default;
      renderApp(HotLoadedApp);
    });
  }

  renderApp(App);
}
