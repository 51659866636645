import React, { Suspense, useEffect, useMemo, useState } from "react";
import {
  Inline,
  Color,
  Corners,
  Stack,
  Center,
  TextSize,
  Text
} from "@ableco/baseline";
import JSONAPI from "utils/jsonapi-parse";
import CheerCard from "./CheerCard";
import { useCheers } from "queries/use-cheers";
import { useMeeting } from "queries/use-meetings";

function LoadingFallback() {
  return (
    <Stack className="flex-1">
      <Text size={TextSize.LG} color={Color.Neutral800}>
        Loading...
      </Text>
    </Stack>
  );
}

const withSuspense = Component => props => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <Component {...props} />
    </Suspense>
  );
};

// Fisher-Yates shuffle
function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function CheersSlider({ meetingId }) {
  const { data: meeting } = useMeeting({ id: meetingId }, { suspense: true });
  const date = meeting.data.attributes.date;
  const meetingDate = new Date(date);
  meetingDate.setHours(0, 0, 0, 0);
  meetingDate.setMonth(meetingDate.getMonth() - 1);
  const fromDate = meetingDate.toISOString().slice(0, 10);
  // Get cheers starting one month ago
  const { data: cheersResponse } = useCheers(
    { from: fromDate },
    { suspense: true }
  );
  const cheers = useMemo(() => {
    const { data } = JSONAPI.parse(cheersResponse);
    // Group cheers by date
    const cheersByDate = (data || []).reduce((acc, cheer) => {
      const cheerDateStr = new Date(cheer.ts * 1000).toISOString().slice(0, 10);
      if (acc[cheerDateStr] === undefined) {
        acc[cheerDateStr] = [cheer];
      } else {
        acc[cheerDateStr].push(cheer);
      }
      return acc;
    }, {});

    // Randomize cheers only by date
    return shuffle(Object.keys(cheersByDate)).reduce((acc, dateStr) => {
      return acc.concat(cheersByDate[dateStr]);
    }, []);
  }, [cheersResponse]);

  const [currentCheer, setCurrentCheer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentCheer === cheers?.length - 1) {
        setCurrentCheer(0);
      } else {
        setCurrentCheer(currentCheer + 1);
      }
    }, 6500);

    return () => {
      clearInterval(interval);
    };
  }, [currentCheer, cheers, setCurrentCheer]);

  if (!cheers) return null;
  return <CheerCard cheer={(cheers || [])[currentCheer]} />;
}

export default withSuspense(CheersSlider);
