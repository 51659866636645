import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HomePage from "components/pages/HomePage";
import MeetingPage from "components/pages/MeetingPage";
import MeetingListPage from "components/pages/MeetingListPage";
import PresentSlidesPage from "components/pages/PresentSlidesPage";
import PollPage from "components/pages/PollPage";
import { useSession } from "contexts/AuthenticationContext";

const AUTH_ROUTES = [
  {
    path: "/meetings/:meetingId/present/:topicId",
    component: PresentSlidesPage,
  },
  {
    path: "/meetings/:meetingId",
    component: MeetingPage,
  },
  {
    path: "/p/:pollId",
    component: PollPage,
  },
  {
    path: "/",
    component: MeetingListPage,
  },
];

const NO_AUTH_ROUTES = [
  {
    path: "/",
    component: HomePage,
  },
];

function MainRoute() {
  const { isLoggedIn } = useSession();
  const routes = isLoggedIn ? AUTH_ROUTES : NO_AUTH_ROUTES;

  return (
    <Router>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </Router>
  );
}

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => {
        return (
          <route.component
            {...props}
            state={{ from: props.location }}
            routes={route.routes}
          />
        );
      }}
    />
  );
}

export default MainRoute;
export { RouteWithSubRoutes };
