import * as React from "react";
import {
  Inline,
  Stack,
  Text,
  TextSize,
  Color,
  FlexDistribution,
  TextWeight,
} from "@ableco/baseline";
import PresentationControl from "./PresentationControl";
import { useParams } from "react-router-dom";
import { useTopic } from "queries/use-topics";

export default function PresentSlidesPage() {
  const { topicId } = useParams();
  const { data: topic } = useTopic({
    id: topicId,
  });

  if (!topic) return null;

  return (
    <Stack p={[8, 0, 0, 0]} space={10} distribution={FlexDistribution.Center}>
      <Inline distribution={FlexDistribution.Center} space={2}>
        <Text
          color={Color.Neutral800}
          weight={TextWeight.Normal}
          size={TextSize.XL}
        >
          Presenting
        </Text>
        <Text
          color={Color.Neutral800}
          weight={TextWeight.SemiBold}
          size={TextSize.XL2}
        >
          {topic.data.attributes.title}
        </Text>
      </Inline>
      {topic && <PresentationControl topic={topic} />}
    </Stack>
  );
}
