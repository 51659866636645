import { resolve } from "url";
import { tableize, pluralize, singularize } from "inflected";

const BASE_URL = "/api/";

function prepareForURL(resourceName, snakeCasify = true) {
  return pluralize(snakeCasify ? tableize(resourceName) : resourceName);
}

function requestPath(
  resourceName,
  { parentName, parentId, snakeCasify, resourceId, rawPath }
) {
  if (typeof rawPath === "boolean" && rawPath) {
    return resourceName;
  }
  let prefix = "";
  if (typeof parentName != "undefined") {
    prefix += `${prepareForURL(parentName, snakeCasify)}/${parentId}/`;
  }
  let url = `${prepareForURL(resourceName, snakeCasify)}`;
  if (typeof resourceId != "undefined") {
    url += `/${resourceId}`;
  }
  return prefix + url;
}

export function read(resourceName, requestParams, options = {}) {
  return request(resourceName, requestParams, {
    ...options,
    method: "GET",
  });
}

export function create(resourceName, requestParams = {}, options = {}) {
  return request(resourceName, requestParams, {
    ...options,
    method: "POST",
  });
}

export function update(resourceName, requestParams = {}, options = {}) {
  return request(resourceName, requestParams, {
    ...options,
    method: "PATCH",
  });
}

export function destroy(resourceName, requestParams = {}, options = {}) {
  return request(resourceName, requestParams, {
    ...options,
    method: "DELETE",
  });
}

function requestOptions(resourceName, requestParams = {}, options = {}) {
  return typeof requestParams.params != "undefined"
    ? {
        ...options,
        body: JSON.stringify({
          [singularize(resourceName)]: { ...requestParams.params },
        }),
      }
    : options;
}

async function request(resourceName, requestParams = {}, options) {
  const { data } = await api(requestPath(resourceName, requestParams), {
    ...requestOptions(resourceName, requestParams, options),
  });
  return data;
}

const NO_CONTENT_RESPONSE = 204;

export async function api(path, options = {}) {
  const res = await fetch(resolve(BASE_URL, path), {
    ...options,
    headers: {
      ...(options.headers ?? {}),
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  if (!res.ok) throw new Error(res.statusText);
  if (res.status == NO_CONTENT_RESPONSE) {
    return { res, data: {} };
  }
  const data = await res.json();
  return { res, data };
}
