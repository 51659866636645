import React from "react";

// adapted from https://github.com/AJHenry/react-google-slides

function constructUrl(
  presentationKey,
  loop,
  slideDuration,
  showControls,
  slideId
) {
  if (!presentationKey) {
    throw new Error(
      "Failed to fetch presentation key, check the presentation url"
    );
  }

  let baseUrl = "https://docs.google.com/presentation/d/";
  baseUrl += `${presentationKey}/embed?`;
  baseUrl += `loop=${loop ? "true" : "false"}`;

  // If slide duration given, add it
  if (slideDuration) {
    baseUrl += `&start=true`;
    baseUrl += `&delayms=${slideDuration * 1000}`;
  }

  if (!showControls) {
    baseUrl += `&rm=minimal`;
  }

  if (slideId) {
    baseUrl += `&present?slide=id.${slideId}`;
  }

  return baseUrl;
}

// Extracts the slide id from the share-able url
function extractSlidesKey(slidesUrl) {
  const regex = new RegExp(
    `(((https|http):\/\/|)docs\.google\.com\/presentation\/d\/)(.+?(?=(\/.+|\/|$)))`
  );

  const match = regex.exec(slidesUrl);

  return match ? match[4] : null;
}

function GoogleSlidesEmbed({
  slidesLink,
  loop = false,
  slideDuration = null,
  showControls = false,
  slideId = null,
  disabled = false,
  ...props
}) {
  const presentationKey = extractSlidesKey(slidesLink);

  const url = constructUrl(
    presentationKey,
    loop,
    slideDuration,
    showControls,
    slideId
  );

  return (
    <iframe
      src={url}
      allowFullScreen={true}
      style={{ pointerEvents: disabled ? "none" : "auto" }}
      {...props}
    />
  );
}

function areSlidesEqual(prevProps, nextProps) {
  return (
    prevProps.slideId === nextProps.slideId &&
    prevProps.slidesLink === nextProps.slidesLink
  );
}

export default React.memo(GoogleSlidesEmbed, areSlidesEqual);
