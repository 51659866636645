import { useQuery } from "react-query";
import { read } from "utils/api";

async function getCheers(from) {
  const response = await read(
    `cheers?include=mentions,author&filter[cheersSince]=${from}&fields[users]=preferredName,lastName,avatarUrl`,
    { rawPath: true }
  );
  return response;
}

export function useCheers({ from }, options = {}) {
  return useQuery("cheers", () => getCheers(from), {
    suspense: true,
    ...options
  });
}
