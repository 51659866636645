import { useQuery } from "react-query";
import { read } from "utils/api";

// Here we will send the GET request to the api and return the response
function getMeetings() {
  return read("meetings");
}

export function useMeetings() {
  return useQuery("meetings", getMeetings);
}

function getMeeting({ id }) {
  return read("meetings", {
    resourceId: id,
  });
}

export function useMeeting({ id }, options) {
  return useQuery(["meetings", Number(id)], () => getMeeting({ id }), options);
}
