import { useMutation, useQueryCache } from "react-query";
import { create } from "utils/api";

// Here we will send the POST request to the api
function vote({ pollOptionId }) {
  return !!pollOptionId
    ? create("answers", {
        parentName: "poll-options",
        parentId: pollOptionId,
        snakeCasify: false,
        params: {},
      })
    : null;
}

export function useVote(handleSuccess) {
  return useMutation(vote, {
    onSuccess() {
      handleSuccess();
    },
  });
}
