import React, { useState, createContext } from "react";

const GlobalContext = createContext({});

function GlobalContextProvider({ children }) {
  const [networkFailed, setNetworkFailed] = useState(false);
  const [resourceNotFound, setResourceNotFound] = useState(false);

  const markAsResourceNotFound = () => setResourceNotFound(true);
  const markAsNetworkFailed = () => setNetworkFailed(true);

  return (
    <GlobalContext.Provider
      value={{
        // Resource not found
        resourceNotFound,
        markAsResourceNotFound,
        // Network has failed
        networkFailed,
        markAsNetworkFailed,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export { GlobalContext };
export default GlobalContextProvider;
