import React, { useMemo } from "react";
import clsx from "clsx";
import {
  Flex,
  Frame,
  Stack,
  Text,
  Button,
  TextSize,
  TextWeight,
  Inset,
  Inline,
  Color,
  TextLeading,
  TextDecoration,
} from "@ableco/baseline";
import { useHistory } from "react-router-dom";
import { useMeetings } from "queries/use-meetings";
import { useUserTopics } from "queries/use-topics";
import JSONAPI from "utils/jsonapi-parse";
import { useSession } from "contexts/AuthenticationContext";
import styles from "./MeetingListPage.module.css";

function MeetingListPage() {
  const session = useSession();
  const userId = session.getUserAttribute("id");
  const { data: meetings } = useMeetings();
  const { data: topicsData } = useUserTopics({ userId });
  const { push } = useHistory();

  const userTopics = useMemo(() => {
    if (topicsData) {
      const { data } = JSONAPI.parse(topicsData);
      return data;
    }
    return [];
  }, [topicsData]);

  return (
    <Frame>
      <Flex p={[2, 8]} distribution="end">
        <a href="/sign_out">
          <Text
            size={TextSize.SM}
            color={Color.Neutral800}
            decoration={TextDecoration.Underline}
          >
            Sign Out
          </Text>
        </a>
      </Flex>
      <Stack className="p-8">
        <Flex
          direction="horizontal"
          alignment="center"
          distribution="between"
          className="mb-8"
        >
          <Text size={TextSize.XL} weight={TextWeight.Bold}>
            All Hands Meetings
          </Text>
          <a
            href="/admin/meetings/new"
            target="blank"
            className="px-4 py-2 border rounded leading-none text-base"
          >
            New Meeting +
          </a>
        </Flex>
        {meetings?.data.map((meeting) => (
          <Flex
            key={meeting.id}
            className={clsx("py-2", styles.item)}
            direction="horizontal"
            alignment="center"
            distribution="between"
          >
            <Text>{meeting.attributes.date}</Text>
            <Inline space={2}>
              <a
                href={`/admin/meetings/${meeting.id}/edit`}
                target="blank"
                className="px-4 py-2 border rounded leading-none text-base"
              >
                Edit Meeting Agenda
              </a>
              <Button onClick={() => push(`/meetings/${meeting.id}`)}>
                <Text color={Color.White}>Present</Text>
              </Button>
            </Inline>
          </Flex>
        ))}

        <Flex
          direction="horizontal"
          alignment="center"
          distribution="between"
          className="mt-16 mb-8"
        >
          <Text size={TextSize.XL} weight={TextWeight.Bold}>
            Your Topics
          </Text>
        </Flex>

        {userTopics.length === 0 && (
          <Text color={Color.Neutral500}>
            You do not have topics to present.
          </Text>
        )}
        {userTopics.length > 0 &&
          userTopics.map((topic) => (
            <Flex
              key={topic.id}
              className={clsx("py-2", styles.item)}
              direction="horizontal"
              alignment="center"
              distribution="between"
            >
              <Text leading={TextLeading.Loose}>
                {topic.meeting.date} | {topic.title}
              </Text>
              <Inline space={2}>
                <a
                  href={`/admin/topics/${topic.id}/edit`}
                  target="blank"
                  className="px-4 py-2 border rounded leading-none text-base"
                >
                  Edit Topic
                </a>
                {topic.kind === "slides" &&
                  (topic.attachmentUrl ? (
                    <Button
                      onClick={() =>
                        push(
                          `/meetings/${topic.meeting.id}/present/${topic.id}`
                        )
                      }
                    >
                      <Text color={Color.White}>Present</Text>
                    </Button>
                  ) : (
                    <Text color={Color.Neutral500} italic={true}>
                      No Slides
                    </Text>
                  ))}
              </Inline>
            </Flex>
          ))}
      </Stack>
    </Frame>
  );
}

export default MeetingListPage;
